import cx from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';

import { Link } from '@app/core/primitives/Link/Link';
import { useStore } from '@app/stores/root/helpers/storeContext';

import styles from './BackUrl.module.scss';

const BackUrlComponent = observer(() => {
  const {
    common: { backUrl },
  } = useStore();

  return backUrl ? (
    <div className={styles.backUrl}>
      <span className={cx(styles.arrow, styles.left)}></span>
      <Link className={styles.link} as={backUrl.as} href={backUrl.href}>
        Вернуться назад
      </Link>
    </div>
  ) : null;
});

export default BackUrlComponent;
