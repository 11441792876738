import ApiService from '@app/service/api';

export const initializeApiClient = (): ApiService => {
  const apiService = new ApiService();

  // if (!!token.nonEmpty()) {
  //   apiService.setHeader('Authorization', `Bearer ${token.get()}`);
  // }

  // if (sessionId.nonEmpty()) {
  //   apiService.setHeader('sessionId', sessionId.get());
  // }

  return apiService;
};
