import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { CommonStore } from '@app/stores/common';
import { UserStore } from '@app/stores/user';
import { ImportStore } from '@app/stores/import';
import { ProjectStore } from '@app/stores/project';

export const RootStore = types.model('root', {
  common: CommonStore,
  user: UserStore,
  project: ProjectStore,
  import: ImportStore,
});

export interface SelfRootStore extends Instance<typeof RootStore> {}
export type IStoreSnapshotIn = SnapshotIn<typeof RootStore>;
export type IStoreSnapshotOut = SnapshotOut<typeof RootStore>;
