import { Instance, types } from 'mobx-state-tree';

import { ProcessListModel } from '@app/types/Process';

import { Actions } from './actions';
import { Views } from './views';

export const ProjectStore = types
  .model('process', {
    _fullListComplex: types.map(ProcessListModel),
    _fullListSimple: types.map(ProcessListModel),
    lastSince: types.maybe(types.number),
  })
  .views(Views)
  .actions(Actions);

export interface SelfProjectStore extends Instance<typeof ProjectStore> {}
