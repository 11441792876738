import { flow } from 'mobx-state-tree';

import { getApiService } from '@app/stores/root/helpers/getEnv';
import { AsyncFuncWithParam } from '@app/types/AsyncFunc';
import { SelfImportStore } from '@app/stores/import/index';
import { getRoot } from '@app/stores/root/helpers/getRoot';
import { apiErrorHandler } from '@app/service/api/helper';

export enum ImportType {
  Equipment = 'equipment',
  Materials = 'materials',
  Ranks = 'ranks',
  Process = 'process',
  ProcessDraft = 'processDraft',
}

const importBook = (
  self: SelfImportStore,
): AsyncFuncWithParam<{ docId; type }, any> =>
  flow(function* fetch({ docId, type }: { docId: string; type: ImportType }) {
    try {
      if (!getRoot(self).user.isAuth) {
        throw new Error('Требуется авторизация');
      }

      yield getApiService(self).request('post', '/import', {
        spreadsheetId: docId,
        docType: type,
      });
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const loadList = (self: SelfImportStore): AsyncFuncWithParam<any, any> =>
  flow(function* fetch(page: number = 0) {
    try {
      if (!getRoot(self).user.isAuth) {
        throw new Error('Требуется авторизация');
      }

      const list: any = yield getApiService(self).request(
        'get',
        `/import/list?page=${page}`,
      );

      const newItems = list.filter(
        item => !self.list.find(r => r.id === item.id),
      );
      if (self.list.length > 0 && page === 0) {
        self.list.unshift(...newItems);
      } else {
        self.list.push(...newItems);
      }
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

export const Actions = self => ({
  importBook: importBook(self),
  loadList: loadList(self),
});
