import React from 'react';

import { Button, ErrorWrapper, InfoWrapper, Text, Title } from './styled';

export const BugsnagError = React.memo(() => {
  return (
    <>
      <ErrorWrapper>
        <InfoWrapper>{renderError()}</InfoWrapper>
      </ErrorWrapper>
    </>
  );

  function renderError() {
    return (
      <>
        <Title>Error</Title>
        <Text>404 error</Text>
      </>
    );
  }
});
