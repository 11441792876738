import { Instance, types } from 'mobx-state-tree';

import { User, UserModel } from '@app/types/User';

import { Actions } from './actions';
import { Views } from './views';

export const UserStore = types
  .model('user', {
    _data: types.maybeNull(UserModel),
    loading: types.boolean,
    _inited: types.boolean,
    _list: types.map(UserModel),
  })
  .views(Views)
  .actions(Actions);

export interface SelfUserStore extends Instance<typeof UserStore> {}
export interface SelfUserStoreNonNullable extends SelfUserStore {
  data: User;
  loading: boolean;
}
