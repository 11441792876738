import { ImportType } from '@app/stores/import/actions';
import { TaskStatus, TypeNames } from '@app/types/ImportTask';

enum StatusNames {
  new = 'В очереди',
  inProgress = 'запущено',
  canceled = 'отменено',
  error = 'ошибка',
  done = 'выполнено',
}

export const Views = self => ({
  getImportTypes() {
    return Object.values(ImportType).map(type => {
      return {
        code: type,
        name: TypeNames[type],
      };
    });
  },
  getTypeName(type: string): string {
    return TypeNames[type];
  },
  getTaskStatus(status: TaskStatus): string {
    return StatusNames[status];
  },
});
