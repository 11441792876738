export const initialState = {
  pageLoaded: false,
  globalLoading: false,
  pageBoundary: {
    loading: false,
    error: null,
  },
  backUrl: undefined,
  units: [],
};
