import { flow } from 'mobx-state-tree';

import { SelfCommonStore } from '@app/stores/common/index';
import { SelfProjectStore } from '@app/stores/project';
import { AsyncFunc, AsyncFuncWithParam } from '@app/types/AsyncFunc';
import { ProcessGesnType } from '@app/types/ProcessGesnType';
import { getRoot } from '@app/stores/root/helpers/getRoot';
import { getApiService } from '@app/stores/root/helpers/getEnv';
import { apiErrorHandler } from '@app/service/api/helper';
import { Unit } from '@app/types/Unit';

const setLoading = (self: SelfCommonStore) => (value: boolean) => {
  self.globalLoading = value;
};

const setPageLoaded = (self: SelfCommonStore) => () => {
  self.pageLoaded = true;
};

const pageBoundaryStartLoader = (self: SelfCommonStore) => () => {
  self.pageBoundary = {
    loading: true,
    error: null,
  };
};

const pageBoundaryFinishLoader = (self: SelfCommonStore) => () => {
  if (self.pageBoundary.loading) self.pageBoundary.loading = false;
};

const pageBoundaryHandlerError = (self: SelfCommonStore) => (error?: any) => {
  self.pageBoundary = {
    loading: false,
    error: error?.data || null,
  };
};

const loadUnits = (self: SelfCommonStore): AsyncFunc<any, void> =>
  flow(function* fetch() {
    try {
      if (!self.units || self.units.length === 0) {
        const units: Unit[] = yield getApiService(self).request(
          'get',
          '/reference/units',
        );

        self.units = units as any;
      }
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const setBackUrl = (self: SelfCommonStore) => (
  val: { as: string; href: string } | undefined,
) => {
  self.backUrl = val;
};

export const Actions = (self: any) => ({
  setPageLoaded: setPageLoaded(self),
  setLoading: setLoading(self),
  pageBoundaryStartLoader: pageBoundaryStartLoader(self),
  pageBoundaryFinishLoader: pageBoundaryFinishLoader(self),
  pageBoundaryHandlerError: pageBoundaryHandlerError(self),
  loadUnits: loadUnits(self),
  setBackUrl: setBackUrl(self),
});
