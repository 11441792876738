import { getRoot as getRootMobx, IAnyStateTreeNode } from 'mobx-state-tree';

import { SelfCommonStore } from '@app/stores/common';

import { SelfRootStore } from '../Root';

export const getRoot = (store: IAnyStateTreeNode): SelfRootStore =>
  getRootMobx(store);

export const getCommon = (store: IAnyStateTreeNode): SelfCommonStore =>
  getRoot(store).common;
