import { Instance, types } from 'mobx-state-tree';

export enum TaskStatus {
  New = 'new',
  InProgress = 'inProgress',
  Canceled = 'canceled',
  Error = 'error',
  Done = 'done',
}

export enum TypeNames {
  equipment = 'Машины и механизмы',
  materials = 'Материалы',
  ranks = 'Разряды',
  process = 'Процесс (норма)',
  processDraft = 'Процесс (в работе)',
}

export const ImportTaskModel = types.model({
  id: types.string,
  spreadsheetId: types.string,
  docType: types.enumeration(Object.keys(TypeNames)),
  status: types.enumeration(Object.values(TaskStatus)),
});

export interface ImportTask extends Instance<typeof ImportTaskModel> {}
