import React from 'react';
import { observer } from 'mobx-react';
import NextLink from 'next/link';

import { useStore } from '@app/stores/root/helpers/storeContext';

import { HeaderDiv } from './styled';

export const Header = observer(() => {
  const {
    user: { isAuth },
  } = useStore();

  return isAuth ? (
    <HeaderDiv>
      <NextLink href="/">ЦСИ</NextLink>
    </HeaderDiv>
  ) : null;
});
