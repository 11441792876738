import { Instance, types } from 'mobx-state-tree';

export enum UserRolesNames {
  user = 'замерщик',
  manager = 'менеджер',
}

export enum UserRoles {
  Manager = 'manager',
  User = 'user',
}

export enum UserStatus {
  Active = 'active',
  NotActive = 'not_active',
  Deleted = 'deleted',
}

export const UserModel = types.model({
  id: types.string,
  name: types.string,
  phone: types.string,
  roles: types.array(types.enumeration(Object.keys(UserRolesNames))),
  status: types.enumeration(Object.values(UserStatus)),
});

export interface User extends Instance<typeof UserModel> {}
