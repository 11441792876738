import styled from 'styled-components';

export const HeaderDiv = styled.div`
  background: #162459;
  box-shadow: 0px 4px 7px rgba(7, 15, 56, 0.12),
    0px 1px 10px rgba(7, 15, 56, 0.12), 0px 3px 4px rgba(7, 15, 56, 0.16);
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* back-color */

  padding: 16px;

  & a {
    color: #ffffff;
  }
`;
