import { initialState as commonInitialState } from '@app/stores/common/initialState';
import { initialState as userInitialState } from '@app/stores/user/initialState';
import { initialState as importInitialState } from '@app/stores/import/initialState';
import { initialState as projectsInitialState } from '@app/stores/project/initialState';

export const InitialStateRoot = {
  common: commonInitialState,
  user: userInitialState,
  project: projectsInitialState,
  import: importInitialState,
};
