import React from 'react';

import { LayoutOne } from './layout-one/LayoutOne';

interface LayoutProps {
  isError?: boolean;
  children: React.ReactNode;
}

export const Layout = React.memo(({ children, isError }: LayoutProps) => {
  return (
    <>
      <LayoutOne isError={isError}>{children}</LayoutOne>
    </>
  );
});
