import { Instance, types } from 'mobx-state-tree';

import { Views } from '@app/stores/common/views';
import { Actions } from '@app/stores/common/actions';
import { UnitModel } from '@app/types/Unit';

export const CommonStore = types
  .model({
    pageLoaded: types.boolean,
    globalLoading: types.boolean,
    pageBoundary: types.model({
      loading: types.boolean,
      error: types.frozen(),
    }),
    units: types.array(UnitModel),
    backUrl: types.maybe(
      types.model({
        href: types.string,
        as: types.string,
      }),
    ),
  })
  .actions(Actions)
  .views(Views);

export interface SelfCommonStore extends Instance<typeof CommonStore> {}
