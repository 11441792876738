import makeInspectable from 'mobx-devtools-mst';
import { applySnapshot } from 'mobx-state-tree';

import { getFromConfig } from '@app/core/libs/getPublicRuntimeConfig';
import { InitialStateRoot } from '@app/stores/root/initialState';
import { RootStore, SelfRootStore } from '@app/stores/root/Root';
import { initialState as userInitialState } from '@app/stores/user/initialState';

import { initializeApiClient } from './initializeApiClient';

let store: SelfRootStore = null as any;

const storeFactory = () =>
  RootStore.create(
    {
      ...InitialStateRoot,
    },
    { api: initializeApiClient() },
  );

export const initializeStore = ({
  isServer,
  snapshot,
}: InitializeStoreProps) => {
  if (isServer) {
    store = storeFactory();
  }

  if (store === null) {
    store = storeFactory();
  }

  if (snapshot) {
    applySnapshot(store, snapshot);
  }

  makeInspectable(store);

  if (!isServer && ((window as any).Cypress || !!getFromConfig('isDev'))) {
    (window as any).store = store;
  }

  return store;
};

export interface InitializeStoreProps {
  isServer: boolean;
  snapshot?: any;
}
