import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import cx from 'classnames';

import { useStore } from '@app/stores/root/helpers/storeContext';
import { AuthForm } from '@app/core/components/auth/AuthForm';
import BackUrlComponent from '@app/modules/layout/layout-one/backUrl/BackUrl';

import { Header } from './header';
import styles from './LayoutOne.module.scss';

interface LayoutProps {
  children: React.ReactNode;
  isError?: boolean;
}

const LayoutOneComponent = ({ children, isError }: LayoutProps) => {
  const {
    user: { isAuth, inited },
  } = useStore();

  useEffect(() => {}, [isAuth, inited]);

  return inited && isAuth ? (
    <div className={styles.component}>
      <div className={styles.container}>
        <Header />
        <BackUrlComponent />
        <div className={cx(styles.main, isError && styles.error)}>
          {children}
        </div>
      </div>
    </div>
  ) : inited && !isAuth ? (
    <div className={styles.component}>
      <div className={styles.container}>
        <div className={cx(styles.main, isError && styles.error)}>
          <AuthForm />
        </div>
      </div>
    </div>
  ) : null;
};

export const LayoutOne = compose<any, LayoutProps>(
  React.memo,
  observer,
)(LayoutOneComponent);
