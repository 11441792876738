import { flow } from 'mobx-state-tree';

import { getApiService, getEnv } from '@app/stores/root/helpers/getEnv';
import { AsyncFunc, AsyncFuncWithParam } from '@app/types/AsyncFunc';
import { SelfProjectStore } from '@app/stores/project/index';
import { getRoot } from '@app/stores/root/helpers/getRoot';
import { apiErrorHandler } from '@app/service/api/helper';
import {
  ProcessProject,
  ProcessProjectList,
  ProcessStatus,
  ProcessType,
} from '@app/types/Process';
import { ProcessGesnType } from '@app/types/ProcessGesnType';
import { ProcessFerType } from '@app/types/ProcessFerType';
import { FerIndexes } from '@app/types/FerIndexes';
import { ProcessTvnType } from '@app/types/ProcessTvnType';

const loadList = (
  self: SelfProjectStore,
) =>
  flow(function* fetch() {
    try {
      const list: ProcessProjectList[] = yield getApiService(self).request(
        'get',
        `/processes${
          self.lastSince && self._fullListComplex
            ? '?since=' + self.lastSince
            : ''
        }`,
      );

      self.lastSince = new Date().valueOf();

      const complexList = list.filter(it => it.type === ProcessType.Complex);
      const simpleList = list.filter(it => it.type === ProcessType.Simple);

      complexList.map(it =>
        it.status !== ProcessStatus.Deleted && it.deleted
          ? self._fullListComplex.delete(it.id)
          : self._fullListComplex.set(it.id, it),
      );
      simpleList.map(it =>
        it.status !== ProcessStatus.Deleted && it.deleted
          ? self._fullListSimple.delete(it.id)
          : self._fullListSimple.set(it.id, it),
      );
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getById = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, ProcessProject> =>
  flow(function* fetch(id: string) {
    try {
      const project: ProcessProject = yield getApiService(self).request(
        'get',
        `/processes/${id}`,
      );

      return project as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const approve = (
  self: SelfProjectStore,
): AsyncFuncWithParam<
  { id: string; data: { type: string; code: string; category: string } },
  void
> =>
  flow(function* fetch({ id, data }) {
    try {
      if (!getRoot(self).user.isManager) {
        throw new Error('Требуется авторизация менеджером');
      }

      yield getApiService(self).request(
        'put',
        `/processes/approve/${id}`,
        data,
      );
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const cancelApprove = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, void> =>
  flow(function* fetch(id: string) {
    try {
      if (!getRoot(self).user.isManager) {
        throw new Error('Требуется авторизация менеджером');
      }

      yield getApiService(self).request('put', `/processes/unApprove/${id}`);
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getGesn = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, ProcessGesnType> =>
  flow(function* fetch(id: string) {
    try {
      const project: ProcessGesnType = yield getApiService(self).request(
        'get',
        `/indicators/gesn/${id}`,
      );

      return project as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getGesnFile = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, string> =>
  flow(function* fetch(id: string) {
    try {
      const link: string = yield getApiService(self).request(
        'get',
        `/indicators/gesnFile/${id}`,
      );

      return link as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getFer = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, ProcessFerType> =>
  flow(function* fetch(id: string) {
    try {
      const project: ProcessFerType = yield getApiService(self).request(
        'get',
        `/indicators/fer/${id}`,
      );

      return project as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getFerFile = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, string> =>
  flow(function* fetch(id: string) {
    try {
      const link: string = yield getApiService(self).request(
        'get',
        `/indicators/ferFile/${id}`,
      );

      return link as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const updateFerIndexes = (
  self: SelfProjectStore,
): AsyncFuncWithParam<FerIndexes & { processId: string }, FerIndexes> =>
  flow(function* fetch(data) {
    try {
      const project: FerIndexes = yield getApiService(self).request(
        'put',
        `/indicators/fer/${data.processId}`,
        {
          ...data,
        },
      );

      return project as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getTvnFile = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, string> =>
  flow(function* fetch(id: string) {
    try {
      const link: string = yield getApiService(self).request(
        'get',
        `/indicators/tvnFile/${id}`,
      );

      return link as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getFsFile = (
  self: SelfProjectStore,
): AsyncFuncWithParam<{ processId: string; experimentId: string }, string> =>
  flow(function* fetch({ processId, experimentId }) {
    try {
      const link: string = yield getApiService(self).request(
        'get',
        `/indicators/diagramFile/${experimentId}/${processId}`,
      );

      return link as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const getTvn = (
  self: SelfProjectStore,
): AsyncFuncWithParam<string, ProcessTvnType> =>
  flow(function* fetch(id: string) {
    try {
      const link: string = yield getApiService(self).request(
        'get',
        `/indicators/tvn/${id}`,
      );

      return link as any;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

export const Actions = self => ({
  loadList: loadList(self),
  getById: getById(self),
  cancelApprove: cancelApprove(self),
  approve: approve(self),
  getGesn: getGesn(self),
  getGesnFile: getGesnFile(self),
  getFer: getFer(self),
  getFerFile: getFerFile(self),
  updateFerIndexes: updateFerIndexes(self),
  getTvnFile: getTvnFile(self),
  getTvn: getTvn(self),
  getFsFile: getFsFile(self),
});
