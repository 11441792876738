import { Option } from 'tsoption';

import { canUseDOM } from '@app/core/helpers/canUseDom';
import { getTokenFromCookie } from '@app/core/helpers/setCookie';
import { User, UserRoles, UserRolesNames, UserStatus } from '@app/types/User';

import { SelfUserStore } from '.';

export const Views = self => ({
  get isAuth() {
    return isAuth(self);
  },
  get isManager() {
    return (
      self._inited &&
      isAuth(self) &&
      data(self)?.roles.includes(UserRoles.Manager)
    );
  },
  get data() {
    return data(self);
  },
  get token() {
    if (!canUseDOM()) return Option.of(undefined);

    return Option.of(getTokenFromCookie());
  },
  get inited() {
    return self._inited;
  },
  getList(status?: UserStatus): User[] {
    const list = Object.values(self._list.toJSON()) as User[];

    return status ? list.filter(item => item.status === status) : list;
  },
  getRoleName(roles: string[]): string {
    let role = roles.includes(UserRoles.Manager)
      ? UserRoles.Manager
      : UserRoles.User;
    const key = Object.keys(UserRolesNames).find(it => it === role);

    return key ? UserRolesNames[key] : role;
  },
  getById(id: string): User | undefined {
    return self._list.get(id);
  },
});

const isAuth = (self: SelfUserStore) => {
  return self._data !== null;
};

const data = (self: SelfUserStore) => {
  return self._data;
};
