import { get } from 'lodash-es';
import { getSnapshot } from 'mobx-state-tree';
import React, { useEffect, useMemo } from 'react';
import 'reset-css';

import { StoreLogger } from '@app/core/components/store-logger';
import { canUseDOM } from '@app/core/helpers/canUseDom';
import '@app/core/styles/fonts.css?CSSModulesDisable';
import '@app/core/styles/layout.scss?CSSModulesEnable';

import { initializeStore } from '@app/initialize/initializeStore';
import bugsnagClient from '@app/modules/bugsnag/bugsnag';
import { BugsnagError } from '@app/modules/error/BugsnagError';
import { Layout } from '@app/modules/layout/Layout';
import { storeContext } from '@app/stores/root/helpers/storeContext';
import { SelfRootStore } from '@app/stores/root/Root';
import { MobxAppContextType } from '@app/stores/root/types/Context';

import ErrorPage from './_error';

import { useRouter } from 'next/router';

const ErrorBoundary = bugsnagClient.getPlugin('react');

interface NextAppProps {
  initialState: { [key: string]: string };
  Component: any;
  pageProps: any;
  asPath?: string;
}

const NextApp = ({ initialState, Component, pageProps }: NextAppProps) => {
  const store = useMemo(
    () =>
      initializeStore({
        isServer: !canUseDOM(),
        snapshot: initialState,
      }),
    [],
  ) as SelfRootStore;

  const { statusCode } = pageProps;

  store.common.loadUnits();

  useEffect(() => {
    const d = document as any;
    const setLoaded = () => {
      (d.getElementById('preloader') as HTMLDivElement).className =
        'preloader loaded';

      store.common.setPageLoaded();

      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('scroll'));
      }, 100);
    };

    const setAuth = async () => {
      store.user.initializeUserCredentials();

      return store.user
        .loadUser()
        .then(() =>
          d.fonts ? d.fonts.ready.then(() => setLoaded()) : setLoaded(),
        );
    };

    setAuth();
  });

  return (
    <ErrorBoundary FallbackComponent={BugsnagError}>
      <storeContext.Provider value={store}>
        <Layout isError={!!statusCode}>
          {!!statusCode ? (
            <ErrorPage statusCode={statusCode} />
          ) : (
            <Component {...pageProps} />
          )}
          <StoreLogger log={false} />
        </Layout>
      </storeContext.Provider>
    </ErrorBoundary>
  );
};

NextApp.getInitialProps = async ({ Component, ctx }: MobxAppContextType) => {
  /* initialize store on server */
  const store = initializeStore({
    isServer: !canUseDOM(),
    snapshot: null,
  });

  ctx.store = store;

  let pageProps = {};

  if (Component.getInitialProps) {
    try {
      pageProps = await Component.getInitialProps(ctx);
    } catch (error) {
      pageProps['statusCode'] = get(error, 'statusCode');
    }
  }

  return {
    initialState: getSnapshot(store),
    isServer: !canUseDOM(),
    pageProps,
  };
};

NextApp.whyDidYouRender = true;

export default NextApp;
