import { Instance, types } from 'mobx-state-tree';

import { ImportTaskModel } from '@app/types/ImportTask';

import { Actions } from './actions';
import { Views } from './views';

export const ImportStore = types
  .model({
    list: types.array(ImportTaskModel),
  })
  .views(Views)
  .actions(Actions);

export interface SelfImportStore extends Instance<typeof ImportStore> {}
